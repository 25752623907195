$primaryColor: #ed692c;

.hero-background {
  background-color: $primaryColor;
  height: calc(100vh - 60px);
  display: flex;
}

.hero-container{
  margin: 150px auto;
  width: 450px;
}

.hero-container h1 {
  color: #fff;
  font-size: 50px;
  text-align: center;
  margin-bottom: 35px;
}

.hero-container input {
  border-radius: 6px;
  color: #474747!important;
  width: 100%;
  border: none;
  display: block;
  font-size: 18px;
  padding: 23px 20px;
  position: relative;
  line-height: 31px;
  box-sizing: border-box;
  vertical-align: middle;
}

.mainSearchContainer{
  position: relative;
  box-shadow: 0 6px 10px rgba(0,0,0,0.1);
}

.autocomplete{
  background-color: #fff;
  border-radius: 0 0 6px 6px;
  top: 71px;
  position: absolute;
  width: 100%;
  padding-bottom: 20px;
}

.autocompleteTitle {
  font-weight: 700;
  border-bottom: 1px solid #f1eeed;
  padding: 20px 20px 5px;
}

.result{
  text-decoration: none;
  color: #000;
  display: block;
  padding: 5px 20px;
}

.result:hover, .resultSmall:hover {
  background-color: #f1eeed;
  cursor: pointer;
}

.invisible{
  visibility: hidden;
}

.autocompleteText{
  padding: 23px 20px;
  color: #474747;
  font-size: 18px;
  line-height: 31px;
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  opacity: .3;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  text-align: left;
}





* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input:focus {
  outline: none;
}

.relative {
  position: relative;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.container {
  position: relative;
  width: 95%;
  max-width: 1000px;
  margin: 0 auto;
}

.mtxs{
  margin-top: 5px;
}

.mts {
  margin-top: 10px
}

.mtsm {
  margin-top: 18px
}

.mtm {
  margin-top: 25px
}

.mtl {
  margin-top: 45px
}

.mtxl {
  margin-top: 80px
}

/* -- - -- - -- -- - -- - -- -- - -- - -- */

.button {
  background-color: #fff;
  border: 1px solid transparent;
  border-color: #474747;
  color: #474747;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  padding: 10px 18px;
  cursor: pointer;
  text-align: center;
  transition: border-color .1s, box-shadow .1s;
  text-decoration: none;
  line-height: 1.5;
  border-radius: 4px;
  display: inline-block;
  width: 100%;
}

.button:hover {
  border-color: #636363;
  color: #636363;
}

.button-cta {
  color: #fff;
  background-color: #ed692c;
  border-color: #ed692c;
}

.button-cta:hover {
  color: #fff;
  border-color: #ee7841;
  background-color: #ee7841;
}

.button-facebook {
  background-color: #3b5998;
  border-color: #3b5998;
  color: #fff;
}

.button-facebook:hover{
  color: #fff;
  background-color: #4c669d;
  border-color: #4c669d;
}

.button-google{
  color: #fff;
  background-color: #DA483B;
  border-color: #DA483B;
}

.button-google:hover{
  color: #fff;
  background-color: #ee5244;
  border-color: #ee5244;
}

/* -- - -- - -- -- - -- - -- -- - -- - -- */

.smallFormContainer {
  width: 300px;
  margin: 0 auto;
}

.formLabel{
  font-weight: 700;
}

.formInput {
  margin-top: 5px;
  margin-bottom: 15px;
  display: block;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #d4d4d4;
  color: #474747;
  padding: 10px;
  width: 100%;
  box-shadow: none;
  height: auto;
  height: 44px;
  text-align: left;
  font-size: 14px;
  transition: border-color .1s, box-shadow .1s;
  text-transform: none;
  line-height: 22px;
  position: relative;
}

select.formInput{
  font-size: 16px;
}

.inputGroup{
  display: flex;
  flex-direction: row;
}

.inputGroup .formInput {
  border-radius: 0;
}

.inputGroup .formInput:first-child {
  border-radius: 4px 0 0 4px;
}

.inputGroup .formInput:last-child {
  border-radius: 0 4px 4px 0;
}

.formRadios {
  margin-top: 5px;
  margin-bottom: 18px;
}
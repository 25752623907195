.userHeader {
  background-color: #fff;
  box-shadow: 0 1px 4px #b5b5b5;
  padding: 40px 0;
}

.userHeaderInner {
  display: flex;
  align-items: center;
}

.userHeaderInner img {
  border-radius: 50%;
  display: inline-block;
  width: 150px;
  margin-right: 30px;
}

.userOptions {
  margin: 25px -1%;
  display: flex;
  flex-wrap: wrap;
}

.userOption {
  background-color: #fff;
  border-radius: 4px;
  width: 48%;
  flex-shrink: 0;
  flex-grow: 0;
  margin: 1%;
}

.userOptionTitle {
  padding: 15px 20px;
  border-bottom: 1px solid #e5e5e5;
}

.userOptionContent {
  padding: 15px 20px;
}

.generalError {
  color: red;
  border-color: red;
  margin-bottom: 15px;
  margin-top: 5px;
  border-width: 2px;
}

.generalError:hover {
  cursor: auto;
  color: red;
  border-color: red;
}

.labelError {
  color: red;
}

.savedProperty {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #000;
}

.savedProperty:not(:last-child) {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e5e5e5;
}

.savedProperty img {
  display: inline-block;
  border-radius: 4px;
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-right: 10px;
}
nav{
    background-color: #fff;
    border-bottom: 1px solid #f4f4f4;
    display: flex;
    justify-content: space-between;
    height: 60px;
    box-sizing: border-box;
}

.logo-div{
    margin: auto 20px;
}

.logo-div span{
    font-weight: 700;
    font-size: 15px;
}

.menu-div ul{
    list-style-type: none;
}

.menu-div li{
    display: inline-block;
    border-left: 1px solid #f4f4f4;
}

.menu-div li a{
    display: inline-block;
    padding: 20px;
    color: #000;
    text-decoration: none;
}

.menu-div li a:hover{
    color: #51a1e6;
}

.activeNav{
    color: #51a1e6;
}
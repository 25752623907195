.property__slider {
  background-color: #fff;
  height: 500px;
}

.property__heading {
  background-color: #fff;
  padding: 20px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border-top: 1px solid #f4f4f4;
  margin-bottom: 40px;
}

.property__heading h1 {
  font-size: 26px;
  font-weight: 700;
  margin: 0;
}

.property__heading h2 {
  color: #7b7b7b;
  font-size: 22px;
  font-weight: 400;
  margin-top: 5px;
}

.property__content {
  margin-right: 320px;
}

.property__content_title {
  border-bottom: 1px solid #dbd3cf;
  font-size: 24px;
  margin: 25px 0 15px;
  padding-bottom: 10px;
}

.property__sidebar {
  /* z-index: 1; */
  position: relative;
  top: -190px;
}

.property__sidebar_inner {
  width: 300px;
  float: right;
  margin-bottom: 10px;
}

.property__sidebar_box {
  background: #fff;
  border: 1px solid #f3f3f3;
  border-top: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.1);
  min-height: 150px;
  padding: 20px;
  margin-bottom: 10px;
}

.property__sidebar_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px 4px 0 0;
  background: #52A1E6;
  color: #fff;
  height: 50px;
  line-height: 50px;
  padding: 0 20px;
}

.property__sidebar_top img {
  display: inline-block;
  width: 32px;
}

.property__sidebar_top img:hover{
  cursor: pointer;
}

.property__sidebar_price {
  font-size: 40px;
  font-weight: 700;
}

.property__sidebar_priceunit {
  font-size: 20px;
  display: inline-block;
  margin-left: 4px;
}

.property__sidebar_details_line {
  display: flex;
  justify-content: space-between;
}

.property__sidebar_report {
  color: rgb(77, 77, 77);
  text-align: center;
  text-decoration: underline;
}

.property__sidebar_report:hover {
  cursor: pointer;
}

.Overlay {
  overflow-y: scroll;
  z-index: 1000;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.5);
}

.Modal {
  position: relative;
  outline: none;
  max-width: 500px;
  margin: 80px auto;
  border-radius: 4px;
  padding: 30px 20px 20px;
  background-color: #fff;
  box-shadow: 0 0 8px rgba(0, 0, 0, .2);
}

.x {
  position: absolute;
  top: 7px;
  right: 7px;
  height: 15px;
  width: 15px;
  padding: 8px;
  box-sizing: content-box;
}

.x:hover {
  cursor: pointer;
}

.property__content_details_title {
  font-size: 18px;
  margin-bottom: 15px;
}

.AdFeatures {
  color: #7b7b7b;
  font-size: 16px;
  font-weight: 400;
  list-style-type: none;
  margin: 0 0 20px;
  overflow: hidden;
  padding: 0;
}

.AdFeatures__item {
  border-bottom: none;
  display: inline-block;
  float: left;
  padding: 6px 10px;
  width: 48.5%;
}

.AdFeatures__item:nth-child(odd) {
  margin-right: 2%;
}

.AdFeatures__item:nth-child(4n+1), .AdFeatures__item:nth-child(4n+2) {
  background-color: #eee;
}

.AdFeatures__item-value {
  float: right;
}

.mapContainer {
  margin: 50px 0;
}

.leaflet-container {
  background-color: #f7f7f7;
  height: 400px;
  width: 100%;
}
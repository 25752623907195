/* Flickity fullscreen v1.0.1
------------------------- */

.flickity-enabled.is-fullscreen {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: hsla(0, 0%, 0%, 0.9);
  padding-bottom: 35px;
  z-index: 1;
}

.flickity-enabled.is-fullscreen .flickity-page-dots {
  bottom: 10px;
}

.flickity-enabled.is-fullscreen .flickity-page-dots .dot {
  background: white;
}

/* prevent page scrolling when flickity is fullscreen */
html.is-flickity-fullscreen {
  overflow: hidden;
}

/* ---- flickity-fullscreen-button ---- */

.flickity-fullscreen-button {
  display: block;
  right: 10px;
  top: 10px;
  width: 40px;
  height: 40px;
  border-radius: 4px;
}

/* right-to-left */
.flickity-rtl .flickity-fullscreen-button {
  right: auto;
  left: 10px;
}

.flickity-fullscreen-button-exit { display: none; }

.flickity-enabled.is-fullscreen .flickity-fullscreen-button-exit { display: block; }
.flickity-enabled.is-fullscreen .flickity-fullscreen-button-view { display: none; }

.flickity-fullscreen-button .flickity-button-icon {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 10px;
  top: 10px;
}
.SingleDatePickerInput__withBorder{
  border-radius: 4px;
}

.DateInput{
  width: 100%;
  border-radius: 4px;
}

.DateInput_input{
  border-radius: 4px;
  padding: 6px;
  font-size: 0.9em;
}

.DateInput_input::placeholder{
  color: inherit;
  font-family: sans-serif;
}

.SingleDatePickerInput_clearDate{
  padding-top: 6px;
  top: 20px;
}
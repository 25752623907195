body{
    background-color: #f7f7f7;
}

.searchContainer{
    width: 95%;
    max-width: 1000px;
    margin: 40px auto;
    padding: 0 15px;
    display: flex;
    flex-direction: row;
}

.sidebar{
    flex: 0 0 12em;
    
    padding-right: 20px;
    border-right: 1px solid #d8d8d8;
    margin-right: 20px;
    /* height: 800px; */
    /* background-color: cadetblue; */
}

.optionTitle{
    font-weight: 700;
    margin-bottom: 10px;
}

.optionTitle span{
    background-color: #d6d6d6;
    padding: 4px;
    font-size: 0.8em;
    border-radius: 4px;
    float: right;
    font-weight: 400;
    color: #000;
}

.radios label{
    margin-bottom: 10px;
    display: block;
}

.radios input{
    margin-right: 5px;
}

.option {
    margin-bottom: 30px;
}

.inputAndIcon{
    display: flex;
    
    /* border: 1px solid #d6d6d6;
    border-radius: 4px; */
}

.inputAndIcon input{
    border: 1px solid #d6d6d6;
    border-radius: 4px;
    width: 100%;
    padding: 8px;
    font-size: 0.9em;
}

.inputAndIcon span{
    display: inline-block;
    margin: auto 6px;
}

.inputAndIcon div{
    background-color: #d6d6d6;
    padding: 7px;
    
    font-size: 0.8em;
}

.emptyField{
    width: 100%;
    border-radius: 4px;
    padding: 6px 6px;
    
    border: 1px solid #d6d6d6;
}

.resetFilterBtn{
    width: 100%;
    border: 1px solid #d6d6d6;
    background-color: transparent;
    padding: 6px;
    border-radius: 4px;
}






.main{
    margin: -10px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-self: flex-start;
}

.card{
    width: 200px;
    /* background-color: #fff; */
    
    padding: 10px;
    width: 33.33%;
}

.cardInner{
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 4px #b5b5b5;
    text-decoration: none;
    display: block;
    color: inherit;
}

.cardInner img{
    width: 100%;
    border-radius: 4px 4px 0 0;
}

.cardTitle{
    font-weight: 700;
}

.desc{
    padding: 10px;
}

.sortBar{
    padding-bottom: 20px;
    border-bottom: 1px solid #d8d8d8;
    margin-bottom: 20px;
}

.autocompleteSmall{
    background-color: #fff;
    top: 59px;
    position: absolute;
    width: 100%;
    padding-bottom: 6px;
    border-radius: 0 0 4px;
    border: 1px solid #d6d6d6;
    font-size: 0.9em;
    z-index: 1;
}

.autocompleteTitleSmall{
    font-weight: 700;
    border-bottom: 1px solid #f1eeed;
    padding: 12px 12px 6px;
}

.resultSmall {
    text-decoration: none;
    color: #000;
    display: block;
    padding: 6px 12px;
}


.locations{
    margin-top: 5px;
}

.locations div{
    margin-bottom: 5px;
}

.closeIcon{
    color: #999;
    cursor: pointer;
    font-size: 20px;
    font-weight: 700;
    display: inline-block;
    margin-right: 4px;
}

.closeIcon:hover{
    color: red;
}